import en from "./i18n/en.json";
import nl from "./i18n/nl.json";

export default defineI18nConfig(() => ({
  legacy: false,
  fallbackLocale: "en",
  messages: {
    en,
    nl,
  },
  /**
   * Default formats seem to be the default formatting according to Intl.DateTimeFormat
   * e.g. console.log(new Intl.DateTimeFormat('nl').format(new Date()));
   */
  datetimeFormats: {
    en: {
      short: {
        year: "numeric",
        month: "long",
        day: "numeric",
      },
      long: {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        timeZoneName: "short",
      },
    },
    nl: {
      short: {
        year: "numeric",
        month: "long",
        day: "numeric",
      },
      long: {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        timeZoneName: "short",
      },
    },
  },
}));
